import React, {RefObject} from 'react';
import Header from '../../components/Header';
import {withRouter} from '../../utils/withRouter';
import getCamera from "../../getCamera";
import "./EnterRoom.css"
import WorkerBuilder from "../../utils/WorkerBuilder";
import QRWorker from '../../utils/QRWorker';
import QRCodeImg from '../../assets/drawable-xxhdpi-v4/app_assets_img_qr_code.png';
import {BeenRecord, DecodedJSON} from "../../Types";
import {NavigateFunction} from "react-router";

interface Props {
    navigate: NavigateFunction;
}

interface IEnterRoom {
    result: string;
    worker: Worker;
    mediaStream?: MediaStream;
}

class EnterRoom extends React.Component<Props, IEnterRoom> {
    private videoRef: RefObject<HTMLVideoElement> = React.createRef();
    private canvasRef: RefObject<HTMLCanvasElement> = React.createRef();
    private mediaStream?: MediaStream;
    private workerInit: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            result: "Loading...",
            worker: new WorkerBuilder(QRWorker),
        }

        this.stop = this.stop.bind(this);

        this.state.worker.onmessage = (message: MessageEvent) => {
            this.setState({result: message.data})
            if (message.data == null)
                this.detectQR();
            else {
                const result: string = message.data;
                if (!result.startsWith("HKEN:0")) {
                    this.detectQR();
                    return;
                }
                const venueId = result.substring(6, 14);
                const json: DecodedJSON = JSON.parse(this.b64DecodeUnicode(result.substring(14)));
                this.hash(`HKEN${venueId}2020`).then((hash: string) => {
                    if (hash !== json.hash) {
                        this.detectQR();
                        return;
                    }
                    this.stop();
                    const placesStr = localStorage.getItem("places");
                    let places: BeenRecord[] = placesStr === null ? [] : JSON.parse(placesStr);
                    places.push({
                        ...json,
                        venueId: venueId,
                        start: new Date().getTime(),
                    })
                    localStorage.setItem("places", JSON.stringify(places))
                    this.props.navigate(-1);
                    setTimeout(() => this.props.navigate("/inroom", {
                        state: {
                            firstScan: true,
                        }
                    }), 2)
                })
            }
        }
    }

    b64DecodeUnicode(str: string) {
        return decodeURIComponent(Array.prototype.map.call(window.atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    async hash(string: string) {
        const utf8 = new TextEncoder().encode(string);
        const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
    }

    componentDidMount() {
        const videoElement = this.videoRef.current!;
        const canvasElement = this.canvasRef.current!;
        getCamera().then((ms: MediaStream | undefined) => {
            if (ms !== undefined) {
                this.mediaStream = ms;
                this.setState({mediaStream: ms});
                videoElement.srcObject = ms;
            }
        })

        window.addEventListener("scroll", this.disableScroll, true);
        videoElement.addEventListener("canplay", e => {
            canvasElement.height = videoElement.videoHeight;
            canvasElement.width = videoElement.videoWidth;
            this.detectQR();
        })
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.disableScroll, true);
        this.state.worker.terminate();
    }

    stop() {
        if (this.mediaStream != null) {
            this.mediaStream.getTracks().map((mt: MediaStreamTrack) => mt.stop());
            this.mediaStream = undefined;
        }
    }

    disableScroll(e: Event) {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    detectQR() {
        const videoElement = this.videoRef.current!;
        const canvasElement = this.canvasRef.current!;
        const canvas = canvasElement.getContext('2d')!;
        if (!this.workerInit) {
            this.state.worker.postMessage(JSON.stringify({
                height: canvasElement.height,
                width: canvasElement.width
            }))
            this.workerInit = true;
        }
        canvas.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        this.state.worker.postMessage(imageData.data);
    }

    render() {
        return (
            <>
                <div className={"page-disable-scroll"} style={{zIndex: 10}}><Header name={"掃描二維碼"}
                                                                                    navigate={this.props.navigate}
                                                                                    stop={this.stop}/></div>
                <div className={"page page-disable-scroll"}>
                    <video className={"video"} onClick={() => this.videoRef.current!.play()} ref={this.videoRef}
                           autoPlay playsInline/>
                    <div className={"white-shadow"}/>
                    <img className={"qrcode-scan"} src={QRCodeImg} alt={"QR Code"}/>
                    <h4 className={"qrcode-scan-txt"}>掃描二維碼</h4>
                </div>
                <canvas style={{display: "none"}} ref={this.canvasRef}/>
            </>
        );
    }
}

export default withRouter(EnterRoom);
