import React from 'react';
import {withRouter} from "../utils/withRouter";
import {NavigateFunction} from "react-router";

interface Props {
    navigate: NavigateFunction;
}

class AutoRedirect extends React.Component<Props> {

    componentDidMount() {
        if (window.location.pathname === "/") window.location.pathname = "/home";
        setTimeout(() => {
            // if (window.location.pathname === "/") this.props.navigate("/home", {replace: true})
        }, 1);
    }

    render() {
        return (<></>);
    }
}

export default withRouter(AutoRedirect);
