import React from "react";
import "./Header.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {IconButton} from "@mui/material";

interface Props {
    name: string;
    navigate?: any;
    stop?: Function;
}

class Header extends React.Component<Props> {

    render() {
        return (
            <div className={"header"}>
                {this.props.navigate ?
                    <IconButton onClick={() => {
                        this.props.stop && this.props.stop!();
                        this.props.navigate(-1);
                    }} className={"backBtn"}><ArrowBackIosIcon
                        style={{color: "white"}}/></IconButton> : <></>}
                {this.props.name}
            </div>
        );
    }
}

export default Header;

// const BackButton = styled.img`
//   height: 20px;
//   top: 14px;
//   left: 16px;
//   position: absolute;
// `;
