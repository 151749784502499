import React from 'react';
import './App.css';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import AutoRedirect from "./containers/AutoRedirect";
import HomeScreen from "./containers/MainScreen/Home";
import MsgScreen from "./containers/MainScreen/Msg";
import LogsScreen from "./containers/MainScreen/Logs";
import SettingsScreen from "./containers/MainScreen/Settings";
import NavWrapper from "./containers/MainScreen/NavWrapper";
import EnterRoom from './containers/EnterRoom/EnterRoom';
import InRoom from './containers/InRoom/InRoom';
import ScanVaccineCode from './containers/ScanVaccineCode/ScanVaccineCode';

class App extends React.Component {
    render() {
        return (
            <>
                <BrowserRouter>
                    <AutoRedirect/>
                    {/*<NavLink to={`/invoices`} key={1}>Hello</NavLink>*/}
                    <Routes>
                        <Route path="/home" element={<NavWrapper/>}>
                            <Route path="/home" element={<HomeScreen/>}/>
                            <Route path="/home/msg" element={<MsgScreen/>}/>
                            <Route path="/home/logs" element={<LogsScreen/>}/>
                            <Route path="/home/settings" element={<SettingsScreen/>}/>
                        </Route>
                        <Route path="/enterroom" element={<EnterRoom/>}/>
                        <Route path="/inroom" element={<InRoom/>}/>
                        <Route path="/scanvaccinecode" element={<ScanVaccineCode/>}/>
                        <Route path="*" element={<Link to={"/home"}>Error 404 {window.location.pathname}</Link>}/>
                    </Routes>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
