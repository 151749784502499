import {BottomNavigation, BottomNavigationAction, createTheme, Theme, ThemeProvider} from '@mui/material';
import React from 'react';
import './NavWrapper.css'
import Home from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_icon_home.png'
import HomeSelected from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_icon_home_selected.png'
import Logs from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_tab_icon_visit_record.png'
import LogsSelected from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_tab_icon_visit_record_selected.png'
import Msg from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_icon_inbox.png'
import MsgSelected from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_icon_inbox_selected.png'
import Settings from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_icon_settings.png'
import SettingsSelected from '../../assets/drawable-xxhdpi-v4/app_assets_tabicons_icon_settings_selected.png'
import {withRouter} from '../../utils/withRouter';
import {Outlet} from 'react-router-dom';
import {NavigateFunction} from "react-router";

interface Props {
    navigate: NavigateFunction;
    location: Location;
}

interface INavWrapper {
    page: string;
    theme: Theme;
    icoHome: string;
    icoLogs: string;
    icoMsg: string;
    icoSettings: string;
}

class NavWrapper extends React.Component<Props, INavWrapper> {

    constructor(props: any) {
        super(props);

        let path = this.detectPath();

        this.state = {
            page: path,
            theme: createTheme({
                components: {
                    MuiBottomNavigation: {styleOverrides: {root: {padding: "15px", boxShadow: "0 0 10px 0px grey"}}},
                    MuiBottomNavigationAction: {
                        defaultProps: {disableRipple: true, disableTouchRipple: true},
                        styleOverrides: {
                            root: {
                                fontWeight: "bold",
                                "&.Mui-selected": {color: "#12b188"}
                            }
                        }
                    },
                },
            }),
            icoHome: path !== "Home" ? Home : HomeSelected,
            icoLogs: path !== "Logs" ? Logs : LogsSelected,
            icoMsg: path !== "Msg" ? Msg : MsgSelected,
            icoSettings: path !== "Settings" ? Settings : SettingsSelected,
        }
    }

    detectPath(pathname: string = this.props.location.pathname) {
        switch (pathname) {
            case "/home":
                return "Home";
            case "/home/logs":
                return "Logs";
            case "/home/msg":
                return "Msg";
            case "/home/settings":
                return "Settings";
            default:
                return "Home";
        }
    }

    render() {
        return (
            <>
                <Outlet/>
                <ThemeProvider theme={this.state.theme}>
                    <BottomNavigation
                        showLabels
                        className={"nav"}
                        value={this.state.page}
                        onChange={(event, newValue) => {
                            let path: string = "Home";
                            switch (newValue) {
                                case 0:
                                    this.props.navigate("/home");
                                    path = this.detectPath("/home");
                                    break;
                                case 1:
                                    this.props.navigate("/home/logs");
                                    path = this.detectPath("/home/logs");
                                    break;
                                case 2:
                                    this.props.navigate("/home/msg");
                                    path = this.detectPath("/home/msg");
                                    break;
                                case 3:
                                    this.props.navigate("/home/settings");
                                    path = this.detectPath("/home/settings");
                                    break;
                            }
                            this.setState({
                                page: newValue,
                                icoHome: path !== "Home" ? Home : HomeSelected,
                                icoLogs: path !== "Logs" ? Logs : LogsSelected,
                                icoMsg: path !== "Msg" ? Msg : MsgSelected,
                                icoSettings: path !== "Settings" ? Settings : SettingsSelected,
                            })
                        }}
                    >
                        <BottomNavigationAction key={"Home"} label={"主頁"}
                                                icon={<img className={"navicon"} src={this.state.icoHome}
                                                           alt={"主頁"}/>}/>
                        <BottomNavigationAction key={"Logs"} label={"出行紀錄"}
                                                icon={<img className={"navicon"} src={this.state.icoLogs}
                                                           alt={"出行紀錄"}/>}/>
                        <BottomNavigationAction key={"Msg"} label={"收件箱"} id={"msgBtn"}
                                                icon={<img className={"navicon"} src={this.state.icoMsg}
                                                           alt={"收件箱"}/>}/>
                        <BottomNavigationAction key={"Settings"} label={"設定"}
                                                icon={<img className={"navicon"} src={this.state.icoSettings}
                                                           alt={"設定"}/>}/>
                    </BottomNavigation>
                </ThemeProvider>
            </>
        );
    }
}

export default withRouter(NavWrapper);
