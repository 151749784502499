import React from 'react';
import "./Home.css"
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    createTheme,
    IconButton,
    Theme,
    ThemeProvider
} from "@mui/material";
import EnterHome from '../../assets/drawable-mdpi-v4/app_assets_img_home_enter.jpg'
import EnterTaxi from '../../assets/drawable-mdpi-v4/app_assets_img_home_taxi.jpg'
import ReportExpose from '../../assets/drawable-mdpi-v4/app_assets_img_home_report.jpg'
import AddVaccineRecord from '../../assets/drawable-mdpi-v4/app_assets_img_home_evt.png'
import ActivateLeaveHomeSafe from '../../assets/drawable-mdpi-v4/app_assets_img_home_hc.png'
import DigitalVaccine from '../../assets/drawable-mdpi-v4/app_assets_icon_vaccine.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Link} from "react-router-dom";
import VenueTimer from "./VenueTimer";
import dayjs from "dayjs";
import "dayjs/locale/zh-hk";

dayjs.locale('zh-hk');

interface Props {
}

interface IHomeScreen {
    theme: Theme;
}

class HomeScreen extends React.Component<Props, IHomeScreen> {

    constructor(props: any) {
        super(props);

        this.state = {
            theme: createTheme({
                components: {
                    MuiCardContent: {styleOverrides: {root: {padding: 0}}},
                    MuiCardActions: {styleOverrides: {root: {padding: "0px 8px"}}}
                },
            }),
        }
    }

    render() {
        return (
            <ThemeProvider theme={this.state.theme}>
                <div className={"page allow-scroll allow-scroll-vertical"}>
                    <div className={"welcome"}>
                        <div className={"welcome-title"}>
                            <div>{dayjs().format('YYYY-MM-DD,dddd')}</div>
                            <h2>記錄你的到訪</h2>
                        </div>
                    </div>

                    <div className={"slider-wrapper"}>
                        <div className={"slides allow-scroll allow-scroll-horizontal"}>
                            <Card>
                                <Link to={"/enterroom"} style={{textDecoration: "none"}}>
                                    <CardActionArea>
                                        <CardContent>
                                            <div style={{position: "relative"}}>
                                                <CardMedia component="img" image={EnterHome} alt="Enter Home"/>
                                                <div className={"slides-txt-div"}>
                                                    <h1 className={"slides-txt slides-txt-title"}>進入</h1>
                                                    <h3 className={"slides-txt"}>掃描安心出行二維碼以紀錄到訪</h3>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" className={"slides-btn-txt"}>按此進入</Button>
                                            <IconButton style={{marginLeft: "auto"}}>
                                                <ArrowForwardIosIcon style={{color: "#12b188"}}/>
                                            </IconButton>
                                        </CardActions>
                                    </CardActionArea>
                                </Link>
                            </Card>

                            <Card>
                                <Link to={"/enterRoom"} style={{textDecoration: "none"}}>
                                    <CardActionArea>
                                        <CardContent>
                                            <div style={{position: "relative"}}>
                                                <CardMedia component="img" image={EnterTaxi} alt="Enter Taxi"/>
                                                <div className={"slides-txt-div"}>
                                                    <h1 className={"slides-txt slides-txt-title"}>上車</h1>
                                                    <h3 className={"slides-txt"}>乘搭的士時請掃描車廂內的車牌</h3>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" className={"slides-btn-txt"}>按此上車</Button>
                                            <IconButton style={{marginLeft: "auto"}}>
                                                <ArrowForwardIosIcon style={{color: "#12b188"}}/>
                                            </IconButton>
                                        </CardActions>
                                    </CardActionArea>
                                </Link>
                            </Card>

                            <Card>
                                <Link to={"/home"} style={{textDecoration: "none"}}>
                                    <CardActionArea>
                                        <CardContent>
                                            <div style={{position: "relative"}}>
                                                <CardMedia component="img" image={ReportExpose} alt="Report exposed"/>
                                                <div className={"slides-txt-div"}>
                                                    <h1 className={"slides-txt slides-txt-title"}>報告確診或初步確診</h1>
                                                    <h3 className={"slides-txt"}>用戶入被確診或初步確診，須上載其安心紀錄給衛生防護中心，協助個案調查及提供感染風險通知給其他相關用戶</h3>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" className={"slides-btn-txt"}>按此報告</Button>
                                            <IconButton style={{marginLeft: "auto"}}>
                                                <ArrowForwardIosIcon style={{color: "#12b188"}}/>
                                            </IconButton>
                                        </CardActions>
                                    </CardActionArea>
                                </Link>
                            </Card>

                            <Card>
                                <Link to={"/scanvaccinecode"} style={{textDecoration: "none"}}>
                                    <CardActionArea>
                                        <CardContent>
                                            <div style={{position: "relative"}}>
                                                <CardMedia component="img" image={AddVaccineRecord}
                                                           alt="Report exposed"/>
                                                <div className={"slides-txt-div"}>
                                                    <h1 className={"slides-txt slides-txt-title"}>電子紀錄接種及檢測紀錄</h1>
                                                    <h3 className={"slides-txt"}>查看電子疫苗接種及檢測紀錄</h3>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" className={"slides-btn-txt"}>按此進入</Button>
                                            <IconButton style={{marginLeft: "auto"}}>
                                                <ArrowForwardIosIcon style={{color: "#12b188"}}/>
                                            </IconButton>
                                        </CardActions>
                                    </CardActionArea>
                                </Link>
                            </Card>

                            <Card>
                                <Link to={"/home"} style={{textDecoration: "none"}}>
                                    <CardActionArea>
                                        <CardContent>
                                            <div style={{position: "relative"}}>
                                                <CardMedia component="img" image={ActivateLeaveHomeSafe}
                                                           alt="Report exposed"/>
                                                <div className={"slides-txt-div"}>
                                                    <h1 className={"slides-txt slides-txt-title"}>香港健康碼系統</h1>
                                                    <h3 className={"slides-txt"}>啟動連結香港健康碼系統</h3>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" className={"slides-btn-txt"}>按此進入</Button>
                                            <IconButton style={{marginLeft: "auto"}}>
                                                <ArrowForwardIosIcon style={{color: "#12b188"}}/>
                                            </IconButton>
                                        </CardActions>
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </div>
                        <div className={"slider-bg"}/>
                    </div>

                    <div className={"page innerPage slides2-div"} style={{paddingBottom: 40}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Card style={{width: "100%", marginRight: "5px"}} sx={{borderRadius: 3}}>
                                <Button className={"subFullSize"}
                                        onClick={() => document.getElementById("msgBtn")?.click()}
                                        component={"div"} style={{width: "100%", height: "100%", padding: 0}}>
                                    <CardActionArea style={{backgroundColor: "#a3e6d8", height: "100%"}}>
                                        <CardContent>
                                            <p className={"black"}
                                               style={{
                                                   marginLeft: "10px",
                                                   fontSize: 9
                                               }}>最後更新{dayjs().format('YYYY-MM-DD')}</p>
                                            <h3 className={"black slider2-txt"}>感染風險通知</h3>
                                            <h2 className={"black slider2-txt"}>0次</h2>
                                            <p className={"white slider2-txt"}
                                               style={{
                                                   width: "80%",
                                                   backgroundColor: "#12b188",
                                                   marginTop: "5px",
                                                   marginLeft: "auto",
                                                   marginRight: "auto",
                                                   marginBottom: "7px",
                                                   borderRadius: 4,
                                                   padding: 5,
                                               }}>更多詳細信息</p>
                                        </CardContent>
                                    </CardActionArea>
                                </Button>
                            </Card>
                            <Card style={{width: "100%", marginLeft: "5px"}} sx={{borderRadius: 3}}>
                                <Link to={"/home/msg"} style={{textDecoration: "none"}}>
                                    <CardActionArea style={{backgroundColor: "#12b188", height: "100%"}}>
                                        <CardContent>
                                            <CardMedia component="img" image={DigitalVaccine} alt="DigitalRecord"
                                                       style={{
                                                           width: "50px",
                                                           marginTop: "13px",
                                                           marginLeft: "auto",
                                                           marginRight: "auto",
                                                       }}/>
                                            <h4 className={"white"} style={{textAlign: "center"}}>電子針卡</h4>
                                        </CardContent>
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </div>
                    </div>

                    <VenueTimer/>
                </div>
            </ThemeProvider>
        );
    }
}

export default HomeScreen;
