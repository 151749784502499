import React, {RefObject} from 'react';
import "./InRoom.css"
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Icon from "../../assets/mipmap-xxxhdpi-v4/splash_icon.png"
import {Button, Collapse, IconButton} from "@mui/material";
import dayjs from "dayjs";
import {BeenRecord} from "../../Types";
import {withRouter} from "../../utils/withRouter";
import {NavigateFunction} from "react-router";
import {Location} from "react-router-dom";
import QRCode from "qrcode.react";

interface Props {
    navigate: NavigateFunction;
    location: Location;
}

interface IInRoom {
    autoLeave: boolean;
    venue: string;
    startTime: number;
    vaccineQRCode: string;
    timerInterval?: number;
}

class InRoom extends React.Component<Props, IInRoom> {

    private hourRef: RefObject<HTMLHeadingElement> = React.createRef();
    private minRef: RefObject<HTMLHeadingElement> = React.createRef();
    private secRef: RefObject<HTMLHeadingElement> = React.createRef();

    constructor(props: any) {
        super(props);

        const record: BeenRecord = JSON.parse(localStorage.getItem("places")!).slice(-1)[0];
        this.state = {
            autoLeave: true,
            venue: record.nameZh,
            startTime: record.start,
            vaccineQRCode: localStorage.getItem("vaccineQR") ? localStorage.getItem("vaccineQR")! : "",
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.disableScroll, true);
        this.setState({
            timerInterval: window.setInterval(() => {
                const secEle = this.secRef.current!;
                const minEle = this.minRef.current!;
                const hourEle = this.hourRef.current!;
                const now = new Date().getTime();

                secEle.textContent = ("0" + dayjs(now).diff(this.state.startTime, 's') % 60).slice(-2);
                minEle.textContent = ("0" + dayjs(now).diff(this.state.startTime, 'm') % 60).slice(-2);
                hourEle.textContent = ("0" + dayjs(now).diff(this.state.startTime, 'h') % 24).slice(-2);
            }, 1000)
        })
    }

    componentWillUnmount() {
        window.clearInterval(this.state.timerInterval)
    }

    disableScroll(e: Event) {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    leaveVenue() {
        let records: BeenRecord[] = JSON.parse(localStorage.getItem("places")!);
        records[records.length - 1].end = new Date().getTime();
        localStorage.setItem("places", JSON.stringify(records));
    }

    render() {
        const firstScan = (this.props.location.state as undefined as any)?.firstScan;

        return (<>
            <div className={"page page-disable-scroll"}>
                <IconButton className={"cross"} size={"large"} onClick={() => this.props.navigate(-1)}><CloseIcon
                    fontSize={"large"} style={{color: "white"}}/></IconButton>
                <img className={"inroom-icon"} src={Icon} alt={"Icon"}/>
                <h2 className={"inroom-venue"}>{this.state.venue}</h2>
                <h3 className={"inroom-time"}>{dayjs(this.state.startTime).format("YYYY-MM-DD HH:mm")}</h3>
                {this.state.vaccineQRCode === "" ?
                    <div className={"vaccine-record-container"}>
                        <div className={"vaccine-record"}>
                            <h3 className={"no-vaccine-record"}>未有接種紀錄</h3>
                            <BlockIcon className={"no-vaccine-record-icon"}/>
                        </div>
                    </div> :
                    <div className={"vaccine-record-container have-vaccine-record-container"}>
                        <div className={"vaccine-record"}>
                            <h3 className={"no-vaccine-record"}>已接種疫苗/豁免接種疫苗</h3>
                            <div className={"have-vaccine-record-qrcode"}>
                                <QRCode value={this.state.vaccineQRCode} fgColor={"#191970"}/>
                            </div>
                        </div>
                    </div>}
                {firstScan === undefined ?
                    <div>
                        <Button className={"leave-btn-2"} onClick={() => {
                            this.leaveVenue();
                            this.props.navigate(-1);
                        }}>現在離場
                            <div className={"timer-div-2 timer-div"}>
                                <h3 ref={this.hourRef}>00</h3>
                                <h3 className={"timer-colon"}>:</h3>
                                <h3 ref={this.minRef}>00</h3>
                                <h3 className={"timer-colon"}>:</h3>
                                <h3 ref={this.secRef}>00</h3>
                            </div>
                        </Button>
                        <Button className={"leave-btn-already"}>我已經離開了</Button>
                        <h4 className={"auto-leave-txt-2"}>於自動離開</h4>
                    </div>
                    :
                    <div>
                        <div className={"timer-div"}>
                            <h3 ref={this.hourRef}>00</h3>
                            <h3 className={"timer-colon"}>:</h3>
                            <h3 ref={this.minRef}>00</h3>
                            <h3 className={"timer-colon"}>:</h3>
                            <h3 ref={this.secRef}>00</h3>
                        </div>
                        <Button className={"leave-btn"}>現在離場</Button>
                        <div style={{display: "flex", flexDirection: "row", margin: "0 0 2% 2%"}}>
                            <IconButton onClick={() => this.setState({autoLeave: !this.state.autoLeave})}>
                                <div className={"auto-leave-tick-container"}>
                                    <Collapse in={this.state.autoLeave} orientation={"horizontal"}>
                                        <DoneIcon className={"auto-leave-tick"} fontSize={"medium"}
                                                  style={{color: "white"}}/>
                                    </Collapse>
                                </div>
                            </IconButton>
                            <h4 className={"auto-leave-txt"}>1小時後自動離開</h4>
                            <Button className={"auto-leave-btn"} disableRipple disableTouchRipple>變更</Button>
                        </div>
                    </div>}
            </div>
        </>);
    }
}

export default withRouter(InRoom);
