import React from 'react';
import Header from "../../components/Header";

class MsgScreen extends React.Component {
    render() {
        return (
            <div className={"page"}>
                <Header name={"收件箱"}/>
                <div className={"page innerPage"}>
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontWeight: "bold"
                        }}
                    >無信息
                    </div>
                </div>
            </div>
        );
    }
}

export default MsgScreen;
