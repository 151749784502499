import React from 'react';
import Header from "../../components/Header";
import './Logs.css'

class LogsScreen extends React.Component {
    render() {
        return (
            <div className={"page"}>
                <Header name={"出行紀錄"}/>
                <div className={"page innerPage"}>
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontWeight: "bold"
                        }}
                    >沒有紀錄
                    </div>
                </div>
                <div className={"innerPage logsTxt"}>
                    存放應用程式內的出行紀錄在31天後會自動刪除。
                </div>
            </div>
        );
    }
}

export default LogsScreen;
