import React, {RefObject} from 'react';
import "./VenueTimer.css"
import Checkout from '../../assets/drawable-xxhdpi-v4/app_assets_icon_checkout.png';
import {BeenRecord} from "../../Types";
import dayjs from "dayjs";
import {withRouter} from '../../utils/withRouter';
import {NavigateFunction} from "react-router";

interface IProps {
    navigate: NavigateFunction;
}

interface IInVenue {
    show: boolean;
}

class VenueTimer extends React.Component<IProps, IInVenue> {

    private timerInterval: number = 0;
    private timeRef: RefObject<HTMLHeadingElement> = React.createRef();

    constructor(props: any) {
        super(props);

        this.state = {show: false}
    }

    componentDidMount() {
        const placesStr = localStorage.getItem("places")
        if (placesStr == null)
            return;
        const venue: BeenRecord = JSON.parse(placesStr).slice(-1)[0];
        if (venue.end !== undefined) return;
        this.timerInterval = window.setInterval(() => {
            this.timeRef.current!.textContent = `${("0" + dayjs(new Date().getTime()).diff(venue.start, 'm') % 60).slice(-2)}:${("0" + dayjs(new Date().getTime()).diff(venue.start, 's') % 60).slice(-2)}`
        }, 1000);
        this.setState({show: true})
    }

    componentWillUnmount() {
        window.clearInterval(this.timerInterval)
    }

    render() {
        if (this.state.show)
            return (
                <div className={"venuetimer-container"} onClick={() => this.props.navigate("/inroom")}>
                    <img src={Checkout} alt={"Checkout"} style={{width: "30%", margin: "10% 0 0 0"}}/>
                    <h4 style={{margin: 0, color: "dimgrey"}}>離開</h4>
                    <h3 style={{margin: 0, color: "dimgrey"}} ref={this.timeRef}>{" "}</h3>
                </div>
            );
        else return (<></>)
    }
}

export default withRouter(VenueTimer);
