// eslint-disable-next-line import/no-anonymous-default-export
export default function QRWorker() {
    let w: number = 0, h: number = 0;
    // eslint-disable-next-line no-restricted-globals
    self.onmessage = (message: MessageEvent) => {
        const nbr = message.data;
        if (w === 0) {
            // @ts-ignore
            const {height, width} = JSON.parse(nbr);
            w = width;
            h = height;
        } else {
            // @ts-ignore
            const code = jsQR(nbr, w, h);
            postMessage(code == null ? null : code.data);
        }
    };
};