import React from 'react';
import Header from "../../components/Header";
import {createTheme, Divider, List, ListItemButton, ListItemText, ThemeProvider} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReplayIcon from '@mui/icons-material/Replay';
import dayjs, {Dayjs} from "dayjs";

interface IProps {
}

interface ISettingsScreen {
    updateDate: Dayjs;
}

class SettingsScreen extends React.Component<IProps, ISettingsScreen> {

    state = {
        updateDate: dayjs()
    }

    private theme = createTheme({
        components: {
            MuiListItemButton: {styleOverrides: {root: {padding: "16px 16px"}}},
            MuiListItemText: {styleOverrides: {primary: {fontSize: "large"}}}
        }
    });

    render() {
        return (
            <div className={"page allow-scroll allow-scroll-vertical innerPage"}>
                <Header name={"設定"}/>
                <ThemeProvider theme={this.theme}>
                    <div className={"page headerMargin"}>
                        <List>
                            <ListItemButton>
                                <ListItemText primary={"關於 安心出行"}/>
                                <h5>版本 3.0.2</h5>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton onClick={() => this.setState({updateDate: dayjs()})}>
                                <ListItemText primary={"每日更新"}
                                              secondary={"最後更新 " + this.state.updateDate.format('YYYY-MM-DD HH:mm:ss')}/>
                                <ReplayIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="語言"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="字型大少"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="應用程式設定"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="個人資料收集聲明"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="私隱政策"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="條款及細則"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="無障礙設施"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton>
                                <ListItemText primary="歡迎使用安心出行"/>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                        </List>
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}

export default SettingsScreen;
