import React, {RefObject} from 'react';
import Header from '../../components/Header';
import {withRouter} from '../../utils/withRouter';
import getCamera from "../../getCamera";
import WorkerBuilder from "../../utils/WorkerBuilder";
import QRWorker from '../../utils/QRWorker';
import QRCodeImg from '../../assets/drawable-xxhdpi-v4/app_assets_img_qr_code.png';
import {NavigateFunction} from "react-router";
import {init, join} from "ramda";

interface Props {
    navigate: NavigateFunction;
}

interface IScanVaccineCode {
    result: string;
    worker: Worker;
    mediaStream?: MediaStream;
}

enum schemaVersionType {
    UNKNOWN = "UNKNOWN",
    EVT1 = "EVT1", // Test Result
    EVT1_1 = "EVT1_1", // Test Result
    VAC2 = "VAC2", // Vaccination Result
    VAC2_1 = "VAC2_1",
    VAC3 = "VAC3", // Vaccination Result
    VAC3_1 = "VAC3_1",
    VAC4 = "VAC4", // Vaccination Result
    VAC4_1 = "VAC4_1",
    VAC8 = "VAC8",
    VAC8_1 = "VAC8_1"
}

class ScanVaccineCode extends React.Component<Props, IScanVaccineCode> {
    private videoRef: RefObject<HTMLVideoElement> = React.createRef();
    private canvasRef: RefObject<HTMLCanvasElement> = React.createRef();
    private mediaStream?: MediaStream;
    private workerInit: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            result: "Loading...",
            worker: new WorkerBuilder(QRWorker),
        }

        this.stop = this.stop.bind(this);

        this.state.worker.onmessage = (message: MessageEvent) => {
            this.setState({result: message.data})
            if (message.data == null) this.detectQR();
            else {
                const result: string = message.data;
                const split: string[] = result.split('|');
                if (split.length <= 3) {
                    this.detectQR();
                    return;
                }
                let parseResult;
                if (split[1] === "EVT" && split.length === 12) parseResult = this.parseQRCode1(schemaVersionType.EVT1, split);
                else if (split[1] === "EVT" && split.length === 13) parseResult = this.parseQRCode1(schemaVersionType.EVT1_1, split);
                else if (split[1] === "VAC" && split[2] === "2") parseResult = this.parseQRCode1(schemaVersionType.VAC2, split);
                else if (split[1] === "VAC" && split[2] === "2.1") parseResult = this.parseQRCode1(schemaVersionType.VAC2_1, split);
                else if (split[1] === "VAC" && split[2] === "3") parseResult = this.parseQRCode2(schemaVersionType.VAC3, split);
                else if (split[1] === "VAC" && split[2] === "3.1") parseResult = this.parseQRCode2(schemaVersionType.VAC3_1, split);
                else if (split[1] === "VAC" && split[2] === "4") parseResult = this.parseQRCode2(schemaVersionType.VAC4, split);
                else if (split[1] === "VAC" && split[2] === "4.1") parseResult = this.parseQRCode2(schemaVersionType.VAC4_1, split);
                else if (split[1] === "VAC" && split[2] === "8") parseResult = this.parseQRCode2(schemaVersionType.VAC8, split);
                else if (split[1] === "VAC" && split[2] === "8.1") parseResult = this.parseQRCode2(schemaVersionType.VAC8_1, split);
                else {
                    this.detectQR();
                    return;
                }
                const {schemaVersion, digitalSignature, stringToBeSigned} = parseResult;
                let publicKey: string = "";
                switch (schemaVersion) {
                    case schemaVersionType.EVT1:
                    case schemaVersionType.EVT1_1:
                    case schemaVersionType.VAC2:
                    case schemaVersionType.VAC2_1:
                        publicKey = "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAErPNELrvyZHvKtCcwIjBRsWItljAosuGsBEG+AjNY0PztjCQffvGm/b38JvXsccFa1s3JVN9id2dXFqxjJ9OtTQ==";
                        break;
                    case schemaVersionType.VAC3:
                    case schemaVersionType.VAC3_1:
                        publicKey = "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEOBxm8jQVg9VN5yGUj7bkvsor73k4r1AvNs2EoXiDYVVQCChFkDKhASMIaqMSbf5H73ub8WT3A+3mn/mnZPqbtQ==";
                        break;
                    case schemaVersionType.VAC4:
                    case schemaVersionType.VAC4_1:
                        publicKey = "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAErO8N4+4Z18Fj2Qeo/w90ybG79YaZzY5AJlOIUn7TtVXuNNTo85f5ofhk5saORUPtrijnENgyxoBkzJyL9UXadQ==";
                        break;
                    case schemaVersionType.VAC8:
                    case schemaVersionType.VAC8_1:
                        publicKey = "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEvTqacaL5XobDH5Qa6I4SmQORnIiDZylaixgFJvBKknoyj5LTHP2XSFs5dhA5TfwzegrKa8XaQtvk5nuRHxhdmw==";
                        break;
                }

                const convertedDigitalSignature = this.asn1derToRS(window.atob(digitalSignature));
                crypto.subtle.importKey("spki", this.str2ab(window.atob(publicKey)), {
                    name: "ECDSA",
                    namedCurve: "P-256"
                }, true, ["verify"]).then((key: CryptoKey) => {
                    crypto.subtle.verify({
                        name: "ECDSA",
                        hash: {name: "SHA-256"}
                    }, key, convertedDigitalSignature, new TextEncoder().encode(stringToBeSigned)).then((cryResult: boolean) => {
                        if (!cryResult) {
                            this.detectQR();
                            return;
                        }
                        window.localStorage.setItem("vaccineQR", result);
                        this.props.navigate(-1);
                    })
                })
            }
        }
    }

    str2ab(str: string, a?: any) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return bufView;
    }

    asn1derToRS(str: string): ArrayBuffer {
        let bufView = new Uint8Array(str.length);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        let rLength: number = parseInt(bufView.slice(3, 4).toString());
        rLength = 32;
        const r = bufView.slice(5, 5 + rLength)
        let sLength = parseInt(bufView.slice(5 + rLength, 6 + rLength).toString());
        sLength = 32;
        const s = bufView.slice(8 + rLength, 8 + rLength + sLength)
        // @ts-ignore
        return new Uint8Array([...r, ...s]).buffer
    }

    parseQRCode1 = (type: schemaVersionType, input: string[]) => {
        const [
            prefix1,
            prefix2,
            formatVersion,
            refId,
            docType,
            iNum,
            specimenCollectionDate,
            specimenType,
            testingPlatform,
            testResult,
            reportDate,
        ] = input;

        return {
            prefix1,
            prefix2,
            formatVersion,
            refId,
            docType,
            iNum,
            specimenCollectionDate,
            specimenType,
            testingPlatform,
            testResult,
            reportDate,
            downloadDate: type === schemaVersionType.EVT1 ? null : input[11],
            digitalSignature: type === schemaVersionType.EVT1 ? input[11] : input[12],
            stringToBeSigned: join("|", init(input)) + "|",
            schemaVersion: type,
        };
    };

    parseQRCode2 = (type: schemaVersionType, input: string[]) => {
        const [
            prefix1,
            prefix2,
            qrCodeVersion,
            keyVersion,
            vacRef,
            iNum,
            label1,
            firstDoseDate,
            firstVaccineName,
            firstVaccineNameTc,
            firstBrandName,
            firstBrandNameTc,
            secondDoseDate,
            secondVaccineName,
            secondVaccineNameTc,
            secondBrandName,
            secondBrandNameTc,
            downloadDate,
            digitalSignature,
        ] = input;

        return {
            prefix1,
            prefix2,
            qrCodeVersion,
            keyVersion,
            vacRef,
            iNum,
            label1,
            firstDoseDate,
            firstVaccineName,
            firstVaccineNameTc,
            firstBrandName,
            firstBrandNameTc,
            secondDoseDate,
            secondVaccineName,
            secondVaccineNameTc,
            secondBrandName,
            secondBrandNameTc,
            downloadDate,
            digitalSignature,
            stringToBeSigned: join("|", init(input)) + "|",
            schemaVersion: type,
        };
    };

    b64DecodeUnicode(str: string) {
        return decodeURIComponent(Array.prototype.map.call(window.atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    async hash(string: string) {
        const utf8 = new TextEncoder().encode(string);
        const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
    }

    componentDidMount() {
        const videoElement = this.videoRef.current!;
        const canvasElement = this.canvasRef.current!;
        getCamera().then((ms: MediaStream | undefined) => {
            if (ms !== undefined) {
                this.mediaStream = ms;
                this.setState({mediaStream: ms});
                videoElement.srcObject = ms;
            }
        })

        window.addEventListener("scroll", this.disableScroll, true);
        videoElement.addEventListener("canplay", e => {
            canvasElement.height = videoElement.videoHeight;
            canvasElement.width = videoElement.videoWidth;
            this.detectQR();
        })
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.disableScroll, true);
        this.state.worker.terminate();
    }

    stop() {
        if (this.mediaStream != null) {
            this.mediaStream.getTracks().map((mt: MediaStreamTrack) => mt.stop());
            this.mediaStream = undefined;
        }
    }

    disableScroll(e: Event) {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    detectQR() {
        const videoElement = this.videoRef.current!;
        const canvasElement = this.canvasRef.current!;
        const canvas = canvasElement.getContext('2d')!;
        if (!this.workerInit) {
            this.state.worker.postMessage(JSON.stringify({
                height: canvasElement.height,
                width: canvasElement.width
            }))
            this.workerInit = true;
        }
        canvas.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        this.state.worker.postMessage(imageData.data);
    }

    render() {
        return (
            <>
                <div className={"page-disable-scroll"} style={{zIndex: 10}}><Header name={"掃描二維碼"}
                                                                                    navigate={this.props.navigate}
                                                                                    stop={this.stop}/></div>
                <div className={"page page-disable-scroll"}>
                    <video className={"video"} onClick={() => this.videoRef.current!.play()} ref={this.videoRef}
                           autoPlay playsInline/>
                    <div className={"white-shadow"}/>
                    <img className={"qrcode-scan"} src={QRCodeImg} alt={"QR Code"}/>
                    <h4 className={"qrcode-scan-txt"}>掃描電子針卡或醫學豁免證明書二維碼</h4>
                </div>
                <canvas style={{display: "none"}} ref={this.canvasRef}/>
            </>
        );
    }
}

export default withRouter(ScanVaccineCode);
